import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { handleCommaEntered, handleInvalidCharacterAfterZero, isCommaEntered, isFractionTooLong, isInvalidCharacterAfterZero, isMultipleCommas, ONLY_DIGITS_COMMA_OR_DOT_REGEXP, shouldAutoPrefixZero, } from './validationFunctions';
const Input = styled.input `
  border: none;
  width: 100%;
  flex: 1;
  font-size: 32px;
  line-height: 36px;
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.error ? props.theme.colors.warning : props.theme.colors.textBodyMain};

  &:focus-visible {
    outline: none;
  }
`;
export const NumberInput = ({ value, onChange, precision, dataTestId, }) => {
    const inputRef = useRef(null);
    const cursorPositionRef = useRef(0);
    useEffect(() => {
        if (inputRef.current && document.activeElement === inputRef.current) {
            inputRef.current.setSelectionRange(cursorPositionRef.current, cursorPositionRef.current);
        }
    }, [value]);
    const handleInputChange = (e) => {
        let { value } = e.target;
        if (e.target.selectionStart) {
            cursorPositionRef.current = e.target.selectionStart;
        }
        if (isInvalidCharacterAfterZero(value)) {
            value = handleInvalidCharacterAfterZero(value) || '';
            // block fetch
            if (value === '0') {
                return;
            }
        }
        if (isCommaEntered(value)) {
            value = handleCommaEntered(value);
        }
        if (shouldAutoPrefixZero(value)) {
            value = '0' + value;
        }
        // now we handle the checks during onChange
        if (ONLY_DIGITS_COMMA_OR_DOT_REGEXP.test(value) &&
            !isMultipleCommas(value) &&
            !(precision && isFractionTooLong(value, precision)) &&
            !(value.includes(',') && value.includes('.'))) {
            onChange(value);
        }
    };
    // value validation on insertion
    const onPaste = (e) => {
        e.preventDefault();
        const pasteText = (e.clipboardData ||
            window.clipboardData).getData('text');
        const cleanedText = pasteText.replace(/[^0-9,.]/g, '');
        let finalText = cleanedText;
        // Check if there is a decimal point or comma in the pasted text
        const parts = cleanedText.split(/[,.]/);
        if (parts.length > 1 && precision) {
            const integerPart = parts[0];
            let fractionPart = parts[1];
            if (fractionPart.length > precision) {
                fractionPart = fractionPart.slice(0, precision);
            }
            finalText = `${integerPart}.${fractionPart}`;
        }
        document.execCommand('insertText', false, finalText);
    };
    return (<Input ref={inputRef} onPaste={onPaste} data-test-id={dataTestId} value={value} type="text" inputMode="decimal" onChange={handleInputChange}/>);
};
