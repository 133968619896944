var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { InitializationError, InitializationErrorsCode, } from './Errors/InitializationErrors';
import { getCurrencies } from './Models/Currencies/Currencies';
import { getMerchant } from './Models/Merchant/Merchant';
import { BLOCKED_USER_ERROR_CODE } from './Services/apiIntegrations/const';
import { createExchangeController } from './Services/controllers/ExchangeController';
import { OrderController } from './Services/controllers/OrderController';
import { OrderFacade, } from './Services/controllers/OrderFacade';
import { PaymentController } from './Services/controllers/PaymentController';
import { StepController } from './Services/controllers/StepController';
import { UserController } from './Services/controllers/UserController';
import { getOrderByToken } from './Services/methods/order';
import { storage } from './Services/storage';
import { FeatureFlag } from './View/Contexts/FeatureFlagContext';
function getOrderOnStart(orderFromSettings) {
    return __awaiter(this, void 0, void 0, function* () {
        const token = orderFromSettings || storage.TOKEN.get();
        if (!token) {
            return null;
        }
        return getOrderByToken(token)
            .then((result) => {
            return result.data;
        })
            .catch((err) => {
            if (orderFromSettings) {
                const code = err.response.status === BLOCKED_USER_ERROR_CODE
                    ? InitializationErrorsCode.BlockedUser
                    : InitializationErrorsCode.UnknowingError;
                throw new InitializationError({
                    code,
                });
            }
            storage.clearAll();
        });
    });
}
function getStartInfo(settings) {
    return __awaiter(this, void 0, void 0, function* () {
        const requiredMethods = [
            getCurrencies(settings),
            getMerchant(),
            getOrderOnStart(settings.orderToken),
        ];
        const res = (yield Promise.all(requiredMethods));
        const [currencies, merchant, order] = res;
        return { currencies, merchant, order };
    });
}
export function createOrderFacade(settings) {
    return __awaiter(this, void 0, void 0, function* () {
        const { smartContractData, defaultValues, externalId } = settings;
        const defaultAmount = defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.fiatAmount;
        const { currencies, merchant, order } = yield getStartInfo(settings);
        const hasCardPaymentByDefault = merchant.features.includes(FeatureFlag.ENABLE_CARD_PAYMENT_BY_DEFAULT);
        if (hasCardPaymentByDefault) {
            storage.PAYMENT_CHANNEL.set('CARD_PAYMENT');
        }
        if (!merchant.geo.allowed) {
            throw new InitializationError({
                code: InitializationErrorsCode.BlockedGeo,
            });
        }
        const exchangeController = createExchangeController(currencies, order, defaultAmount, smartContractData, merchant.geo.europeanUnion);
        if (order) {
            yield exchangeController.getRates();
            exchangeController.stopWatching();
        }
        const orderController = new OrderController(order, externalId);
        const userController = new UserController(order === null || order === void 0 ? void 0 : order.userInfo);
        const stepController = new StepController(order === null || order === void 0 ? void 0 : order.currentStep, order === null || order === void 0 ? void 0 : order.stepsHistory, Boolean(settings.orderToken));
        const paymentController = new PaymentController();
        const orderFacade = new OrderFacade(stepController, userController, orderController, exchangeController, paymentController, currencies, defaultValues);
        return [
            {
                stepController,
                orderFacade,
            },
            currencies,
            merchant,
        ];
    });
}
