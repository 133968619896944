import { FeatureFlag, useFeatureFlag } from '../../Contexts/FeatureFlagContext';
import React from 'react';
export const Burger = ({ onClick }) => {
    const enableWhiteLabel = useFeatureFlag(FeatureFlag.ENABLE_WHITELABEL);
    if (enableWhiteLabel) {
        return null;
    }
    return (<button type="button" className="text-[--main] hover:scale-110 transition-transform duration-300" data-test-id="button-menu-open" onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <title>Menu</title>
        <line x1="4" x2="20" y1="12" y2="12"/>
        <line x1="4" x2="20" y1="6" y2="6"/>
        <line x1="4" x2="20" y1="18" y2="18"/>
      </svg>
    </button>);
};
